/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
        path: "/login",
        name: "Login",
        view: "Login",
    },
    {
        path: "/dashboard/:id?",
        name: "Dashboard",
        view: "Dashboard",
    },
    {
        path: "/:id",
        name: "Campaign",
        view: "Campaign",
    },
    {
        path: "/confirm-payment",
        name: "ConfirmPayment",
        view: "ConfirmPayment",
    },
    {
        path: "/payment-result",
        name: "PaymentResult",
        view: "PaymentResult",
    },
];
