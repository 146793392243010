var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app',{staticClass:"main-background",attrs:{"id":"main-background"}},[_c('router-view',{staticStyle:{"width":"960px","z-index":"1"}}),_c('v-footer',{staticClass:"main-footer",attrs:{"id":"footer"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"mb-8 mt-5 contact-us-button",attrs:{"outlined":""},on:{"click":_vm.openContactUsDialog}},[_vm._v("Contact Us")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.contactUsDialog),callback:function ($$v) {_vm.contactUsDialog=$$v},expression:"contactUsDialog"}},[_c('v-card',{staticClass:"pa-4"},[(_vm.messageSent)?_c('div',{staticClass:"mb-4"},[_c('v-card-title',{staticClass:"pb-2 px-0 pt-0"},[_vm._v("Thank You!")]),_c('div',{staticClass:"body-2"},[_vm._v(" Your message is very important to us. We will get back to you ASAP ")])],1):_c('div',[_c('v-card-title',{staticClass:"pb-2 pl-1 pt-0",staticStyle:{"color":"#4289b0"}},[_vm._v("Contact Us")]),_c('v-text-field',{ref:"contactUsName",staticClass:"px-2",attrs:{"label":"Name","color":"#4289b0","rules":[_vm.required]},model:{value:(_vm.contactUsName),callback:function ($$v) {_vm.contactUsName=$$v},expression:"contactUsName"}}),_c('v-text-field',{ref:"contactUsEmail",staticClass:"px-2",attrs:{"label":"Email","color":"#4289b0","rules":_vm.emailRules},model:{value:(_vm.contactUsEmail),callback:function ($$v) {_vm.contactUsEmail=$$v},expression:"contactUsEmail"}}),_c('v-text-field',{ref:"contactUsPhone",staticClass:"px-2",attrs:{"color":"#4289b0","label":"Phone Number","rules":[
                        (v) => !!v || 'A Valid Phone Number Is Required.',
                        (v) =>
                            !v ||
                            ([10, 11].includes(
                                v.replace(/-/g, '').length
                            ) &&
                                /^\d+$/.test(v)) ||
                            'A Valid Phone Number Is Required.',
                    ]},model:{value:(_vm.contactUsPhone),callback:function ($$v) {_vm.contactUsPhone=$$v},expression:"contactUsPhone"}}),_c('v-textarea',{ref:"contactUsMessage",staticClass:"px-2",attrs:{"solo":"","color":"#4289b0","label":"Message","rules":[_vm.required]},model:{value:(_vm.contactUsMessage),callback:function ($$v) {_vm.contactUsMessage=$$v},expression:"contactUsMessage"}}),(_vm.contactUsError)?_c('v-card-text',{staticClass:"pt-0 error--text"},[_vm._v(" "+_vm._s(_vm.contactUsError)+" ")]):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#4289b0","outlined":""},on:{"click":_vm.closeContactUs}},[_vm._v("Close")]),(!_vm.messageSent)?_c('v-btn',{staticClass:"button",attrs:{"loading":_vm.sending},on:{"click":_vm.contactUS}},[_vm._v("Send")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }