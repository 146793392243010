// https://vuex.vuejs.org/en/actions.html

export default {
    AUTH_REQUEST({ commit }, user) {
        return new Promise((resolve) => {
            commit("AUTH_REQUEST");

            localStorage.setItem("user-token", user.token);

            commit("AUTH_SUCCESS", {
                token: user.token,
            });

            resolve(true);
        });
    },
    AUTH_LOGOUT({ commit }) {
        return new Promise((resolve) => {
            localStorage.removeItem("user-token");

            commit("AUTH_LOGOUT");

            resolve();
        });
    },
};
