<template>
    <div>
        <v-app id="main-background" class="main-background">
            <!-- <v-toolbar
                class="header"
                v-bind:class="{
                    'mb-9': responsive,
                    'mb-12 mt-3': !responsive,
                }"
            >
                <v-layout justify-center align-center class="pt-12">
                    <v-img
                        v-if="responsive"
                        class="mr-2"
                        style="max-width: 85px;"
                        src="https://utaw.blob.core.windows.net/images/treeonfield.png"
                        height="51"
                        width="50"
                        contain
                    />
                    <v-img
                        v-if="!responsive"
                        class=""
                        style="max-width: 145px;"
                        src="https://utaw.blob.core.windows.net/images/treeonfield.png"
                        height="150"
                        width="140"
                        contain
                    />
                </v-layout>
            </v-toolbar> -->
            <router-view style="width: 960px; z-index: 1;"></router-view>
            <v-footer class="main-footer" id="footer">
                <v-layout justify-center>
                    <v-btn
                        class="mb-8 mt-5 contact-us-button"
                        outlined
                        @click="openContactUsDialog"
                        >Contact Us</v-btn
                    >
                </v-layout>
            </v-footer>
        </v-app>
        <v-dialog v-model="contactUsDialog" persistent max-width="500">
            <v-card class="pa-4">
                <div v-if="messageSent" class="mb-4">
                    <v-card-title class="pb-2 px-0 pt-0"
                        >Thank You!</v-card-title
                    >
                    <div class="body-2">
                        Your message is very important to us. We will get back
                        to you ASAP
                    </div>
                </div>
                <div v-else>
                    <v-card-title class="pb-2 pl-1 pt-0" style="color: #4289b0;"
                        >Contact Us</v-card-title
                    >
                    <!-- <v-select
                        class="px-1"
                        v-model="selectedCampaign"
                        :items="campaignList"
                        label="Department"
                        ref="selectedCampaign"
                        item-text="displayName"
                        item-value="id"
                        :rules="[required]"
                        color="#4289b0"
                        return-object
                        single-line
                    ></v-select> -->
                    <v-text-field
                        class="px-2"
                        label="Name"
                        color="#4289b0"
                        v-model="contactUsName"
                        ref="contactUsName"
                        :rules="[required]"
                    ></v-text-field>
                    <v-text-field
                        class="px-2"
                        label="Email"
                        color="#4289b0"
                        v-model="contactUsEmail"
                        :rules="emailRules"
                        ref="contactUsEmail"
                    ></v-text-field>
                    <v-text-field
                        class="px-2"
                        color="#4289b0"
                        label="Phone Number"
                        v-model="contactUsPhone"
                        :rules="[
                            (v) => !!v || 'A Valid Phone Number Is Required.',
                            (v) =>
                                !v ||
                                ([10, 11].includes(
                                    v.replace(/-/g, '').length
                                ) &&
                                    /^\d+$/.test(v)) ||
                                'A Valid Phone Number Is Required.',
                        ]"
                        ref="contactUsPhone"
                    ></v-text-field>
                    <v-textarea
                        class="px-2"
                        solo
                        color="#4289b0"
                        v-model="contactUsMessage"
                        label="Message"
                        ref="contactUsMessage"
                        :rules="[required]"
                    ></v-textarea>
                    <v-card-text v-if="contactUsError" class="pt-0 error--text">
                        {{ contactUsError }}
                    </v-card-text>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#4289b0" outlined @click="closeContactUs"
                        >Close</v-btn
                    >
                    <v-btn
                        v-if="!messageSent"
                        class="button"
                        :loading="sending"
                        @click="contactUS"
                        >Send</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        contactUsDialog: false,
        contactUsError: "",
        contactUsName: "",
        contactUsEmail: "",
        contactUsPhone: "",
        contactUsMessage: "",
        sending: false,
        messageSent: false,
        campaignList: [],
        selectedCampaign: "",

        required: (value) => !!value || "Required.",

        emailRules: [
            (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return !value || pattern.test(value) || "Invalid e-mail.";
            },
            (value) => !!value || "Required",
        ],

        responsive: false,

        campaignName: "",
    }),
    async created() {},
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    methods: {
        onResponsiveInverted() {
            if (window.innerWidth < 551) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        },
        openContactUsDialog() {
            if (
                this.$store.state.app.account &&
                this.$store.state.app.account.name
            ) {
                this.contactUsName = this.$store.state.app.account.name;
                this.contactUsPhone = this.$store.state.app.account.cellPhone.replaceAll(
                    "-",
                    ""
                );
                this.contactUsEmail = this.$store.state.app.account.emailAddress;
            }

            this.contactUsDialog = true;
        },
        closeContactUs() {
            this.contactUsDialog = false;
            this.contactUsError = "";
            this.contactUsName = "";
            this.contactUsEmail = "";
            this.contactUsMessage = "";
            this.contactUsPhone = "";

            this.messageSent = false;
        },
        async contactUS() {
            if (
                ![
                    this.$refs.contactUsName.validate(true),
                    this.$refs.contactUsEmail.validate(true),
                    this.$refs.contactUsPhone.validate(true),
                    this.$refs.contactUsMessage.validate(true),
                ].every(Boolean)
            )
                return;

            this.sending = true;

            try {
                await this.$http.post("contactUS", {
                    accountID: this.$store.state.app.account.accountId,
                    name: this.contactUsName,
                    email: this.contactUsEmail,
                    phone: this.contactUsPhone,
                    department: "YK_CAMP",
                    message: this.contactUsMessage,
                });

                this.sending = false;
                this.messageSent = true;
            } catch (e) {
                this.sending = false;

                this.contactUsError = "Error sending message.";
            }
        },
    },
};
</script>
<style>
/*.main-background {
    background: #bdbdbd !important;
}
.main-background:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 30%;
    background: #4289b0 !important;
    z-index: 0;
}
.main-footer {
    background: #bdbdbd !important;
}*/
.main-background {
    /*background: #bdbdbd !important;*/
    background-image: linear-gradient(
            to bottom,
            rgba(245, 246, 252, 0.52),
            rgb(212 255 210 / 73%)
        ),
        url(https://utaw.blob.core.windows.net/images/nice-field.jpg) !important;
    background-size: cover !important;
    color: white !important;
}
.main-footer {
    background-color: transparent !important;
}
.main-background:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 30%;
    /*background: #bdbdbd !important;*/
    z-index: 0;
}
.header {
    z-index: 2 !important;
    background-color: unset !important;
    box-shadow: none !important;
    max-height: 64px;
}
.bsd {
    text-align: left !important;
    font: Bold 20px/18px Roboto;
    letter-spacing: 0 !important;
    color: #fff !important;
}
.contact-us-button {
    color: #4289b0 !important;
    background-color: #fff !important;
}
.button {
    text-align: left !important;
    font: Medium 14px/20px Roboto;
    letter-spacing: 0 !important;
    color: #ffffff !important;
    background: #4289b0 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 2px #0000003d;
    border-radius: 2px;
    opacity: 1;
}
@font-face {
    font-family: "uta-font";
    src: url(assets/fonts/Revue.eot);
    src: url(assets/fonts/Revue.eot?#iefix) format("embedded-opentype"),
        url(assets/fonts/Revue.woff) format("woff"),
        url(assets/fonts/Revue.ttf) format("truetype");
}

.main-header {
    font-family: "uta-font" !important;
    font-size: 3.75rem !important;
    font-weight: 300 !important;
}
.main-header-mobile {
    font-family: "uta-font" !important;
    font-size: 1.5rem !important;
    font-weight: 300 !important;
}
.mobile-header-image {
    height: 210px !important;
}
.header-image {
    height: 358px !important;
}
.login-title {
    text-align: left !important;
    font: Bold 20px/24px Roboto;
    letter-spacing: 0 !important;
    color: #4289b0 !important;
}
.yk-card {
    border-radius: 28px !important;
}
</style>
