import { set, toggle } from "@/utils/vuex";

export default {
    setDrawer: set("drawer"),
    setImage: set("image"),
    setColor: set("color"),
    toggleDrawer: toggle("drawer"),

    setCampaignDetails(state, { campaignName, image }) {
        state.campaignName = campaignName;
        state.campaignImage = image;
    },
    setPayment(state, payment) {
        state.payment = payment;
    },
    setPaymentFields(state, paymentFields) {
        state.paymentFields = paymentFields;
    },
    setDonor(state, { donor, relatedStudents, transactions, phone, pin }) {
        state.donor = donor;
        state.relatedStudents = relatedStudents;
        state.transactions = transactions;
        state.pin = pin;
        state.phone = phone;
    },
    setAccount(state, { account, transactions }) {
        state.account = account;
        state.transactions = transactions;
    },
    setManual(state, manual) {
        state.manual = manual;
    },
};
