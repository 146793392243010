export default {
    drawer: null,
    color: "success",
    image:
        "https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg",
    payment: null,
    donor: null,
    account: null,
    relatedStudents: null,
    transactions: null,
    manual: null,
    paymentFields: null,
    pin: null,
    campaignName: "",
    phone: null,
};
