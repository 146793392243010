import Vue from "vue";
import moment from "moment";

Vue.filter("time", function(value) {
    if (value) {
        return moment(value).format("MM/DD/YYYY h:mm:ss A");
    }
});
Vue.filter("date", function(value) {
    if (value) {
        return moment(value).format("L");
    }
});
Vue.filter("currency", function(value) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    return formatter.format(value);
});
Vue.filter("phone", function(phoneNumber) {
    if (!phoneNumber) return "";

    if (phoneNumber.length == 11) {
        phoneNumber = phoneNumber.substring(1);
    }

    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return [
            phoneNumber.length == 10 ? "" : match[1] + " ",
            "(",
            match[2],
            ") ",
            match[3],
            "-",
            match[4],
        ].join("");
    }
    return null;
});
