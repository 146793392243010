
export default {
    ["AUTH_REQUEST"]: state => {
        state.status = "loading";
    },
    ["AUTH_SUCCESS"]: (state, { token }) => {
        state.status = "success";
        state.token = token;
    },
    ["AUTH_LOGOUT"]: state => {
        state.status = "";
        state.token = "";
    },
    ["AUTH_ERROR"]: state => {
        state.status = "error";
    }
};

